import FrontPageApi from 'src/services/api/wp/FrontPageApi';
import { GET_HOMEPAGE } from 'src/services/CONSTANTS';
import { QueryTuple } from '../types';

const getHomePage = (params: any = null): QueryTuple => {
  return [
    [GET_HOMEPAGE, params],
    async () => (await new FrontPageApi().get(params)).data,
  ];
};

export { getHomePage };
