import Blocks from '@components/containers/Blocks';
import MainTemplate from '@components/containers/templates/MainTemplate';
import { getHomePage } from 'src/services/api/queries/getters/getHomePage';
import { useHomePageQuery } from 'src/services/api/queries/hooks/useHomePageQuery';
import { withQuery } from 'src/services/api/queries/withQuery';

export default function HomePage() {
  const homePageData = useHomePageQuery().data;

  if (homePageData) {
    const { yoast_head, gutenberg_blocks, meta_data } = homePageData;
    return (
      <MainTemplate
        yoast_head={yoast_head}
        announcement={meta_data?.announcement || ''}
      >
        <Blocks blocks={gutenberg_blocks} />
      </MainTemplate>
    );
  }

  return <></>;
}

export const getStaticProps = withQuery([getHomePage()]);
