import { IApi } from '../types/IApi';
import axiosInstance from './axiosInstance';

class FrontPageApi implements IApi {
  basePath = 'wp/v2/frontpage';

  async get(params?: any) {
    return axiosInstance.get(this.basePath, params);
  }
}

export default FrontPageApi;
